<template>
  <div class="orderconfirm-wrap">
    <div class="bread-wrap">
      <span @click="navigateBack" class="backbtn">
        <i class="el-icon-arrow-left"></i>
      </span>
      <span class="bread-title">订单确认</span>
    </div>
    <div class="order-wrap">
      <div class="order-left">
        <p class="title-wrap">
          <i class="iconfont icon-jiaofeizhangdan titleicon"></i>
          <span class="title">订单信息</span>
        </p>
        <div class="ff-detail">
          <span class="dtcontent">路由器类型</span>
          <span class="dttext">{{ payload.routerType }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">购买数量</span>
          <span class="dttext">{{ payload.buyCount }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">收件人</span>
          <span class="dttext">{{ receiver.name }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">收件号码</span>
          <span class="dttext">{{ receiver.phone }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">收件地址</span>
          <span class="dttext" style="width: 180px; line-break: anywhere;"
          >{{ receiver.address }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">创建时间</span>
          <span class="dttext">{{ nowDate }}</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">支付方式</span>
          <span class="dttext">{{payText}}</span>
        </div>
        <div class="ff-detail">
          <span class="dtprice">合计费用</span>
          <span class="dtprice">{{ TotalAmount }} 元</span>
        </div>
        <el-button
          v-if="amountPay"
          @click="payNow"
          class="paybtn"
          size="small"
          type="primary"
          >立即支付</el-button>
      </div>
      <div class="order-right" v-if="!amountPay">
        <div class="qrcode-wrap">
          <div class="qrcode" id="qrcodeImg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatdate from "@/utils/format";
import navigation from "@/utils/navigation";
import QRCode from "qrcodejs2";
import {centToYuan} from "@/views/lines/Router/Constants.js";
import {PAY_TYPE_MAP} from "@/utils/constants.js";
export default {
  name: "routerOrderConfirm",
  data() {
    const date = new Date();
    return {
      checkTimer: null,
      TradeNo: "",
      TotalAmount: 0,
      nowDate: formatdate(parseInt(date.getTime() / 1000), "YYYY-MM-DD"),
      amountPay: false,
      payText: "",
      payType: "",
      payload: {},
      receiver: {}
    };
  },
  watch: {
    '$route.params.payload': {
      handler: function (payload) {
        if (payload) {
          this.payload = payload;
          if (payload.receiver) {
              this.receiver = payload.receiver
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const payType = PAY_TYPE_MAP.getInstanceByType(this.payload.PayType)
    this.payType = payType.type
    this.payText = payType.text
    this.amountPay = payType.type === PAY_TYPE_MAP.BALANCE.type

    this.$store
      .dispatch("routers/createRouter", { ...this.payload })
      .then((res) => {
          this.TradeNo = res.TradeNo;
          this.TotalAmount = centToYuan(res.TotalAmount);
          if (!this.amountPay) {
            this.getQrCode();
          }
      })
      .catch((e) => {
        this.$message.error("创建路由器订单失败, " + e);
      });
    // console.log(this.$route.params);
  },
  beforeDestroy() {
    this.clearCheckTimer()
  },
  methods: {
    qrcode(url) {
      return new QRCode("qrcodeImg", {
        width: 230,
        height: 230,
        text: url,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
    clearCheckTimer() {
      if (this.checkTimer != null) {
        clearInterval(this.checkTimer);
        this.checkTimer = null;
      }
    },
    navigateBack() {
      this.clearCheckTimer();
      navigation.back();
    },
    async navigation(url) {
      await this.$alert('路由器购买成功，我们会将路由器邮寄到您填写的地址，请耐心等待！', '提示', {
        confirmButtonText: '知道了',
        showClose: false,
        closeOnClickModal: false
      }).catch(() => {})

      navigation.navigateTo({
        url,
        params: {
          payload: {
            listRouter: true
          }
        }
      });
    },
    getPayConfirm() {
      this.checkTimer = setInterval(() => {
        this.$store
          .dispatch("line/getpayconfirm", {
            TradeNo: this.TradeNo,
            PayType: this.payType,
            OrderType: 'Router'
          })
          .then((res) => {
            if (res.RetCode === 0 && res.Status === "Paid") {
              clearInterval(this.checkTimer);
              this.$message.success("支付成功！");
              this.navigation("soft_router");
            } else if (res.RetCode !== 0) {
              clearInterval(this.checkTimer);
            }
          });
      }, 1000);
    },
    getQrCode() {
      const payload = {
        TradeNo: this.TradeNo,
        PayType: this.payType,
        OrderType: 'Router'
      };

      this.$store.dispatch("line/getpaymentparams", { ...payload }).then((res) => {
        if (res.RetCode === 0) {
          this.qrcode(res.PayReq);
          this.getPayConfirm();
        }
      });
    },
    payNow() {
      const payload = {
        TradeNo: this.TradeNo,
        PayType: PAY_TYPE_MAP.BALANCE.type,
        OrderType: 'Router'
      };
      this.$store.dispatch("line/getpaymentparams", { ...payload }).then((res) => {
        // console.log("amount pay response: " + JSON.stringify(res));
        if (res.RetCode === 0) {
          this.$message.success("支付成功！");
          this.navigation("soft_router");
        } else {
          this.$message.error("支付失败！");
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
